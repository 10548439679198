<script lang="ts">
export default defineComponent({
  props: {
    buttonName: {
      type: String,
      required: true,
    },
    urlButton: {
      type: String,
      default: '#',
    },
  },
})
</script>

<template>
  <div
    class="relative mx-auto bg-center bg-no-repeat"
    style="
      max-width: 1600px;
      background-image: url('https://doechk56wu3z8.cloudfront.net/img/img_background_order_now.svg');
    "
  >
    <div
      class="grid md:grid-cols-6 gap-12 md:gap-20 container mx-auto py-30 max-w-6xl px-4 md:px-10"
    >
      <div class="col-span-2 z-10">
        <nuxt-img
          provider="s3"
          src="/img_chicken_order_now.svg"
          alt="Image of Chicken"
          class="w-48 md:w-full"
          data-aos="flip-left"
          data-aos-delay="250"
          data-aos-offset="200"
          sizes="(max-width: 768px) 90vw, (max-width: 1200px) 50vw, 33vw"
          width="286"
          height="357"
          loading="lazy"
        />
      </div>
      <div class="col-span-4 self-center z-10">
        <h2
          class="text-h4w md:text-h4w font-bold font-serif italic leading-14 md:leading-14 text-green mb-10"
        >
          {{ $t('components.sectionOrderNow.title') }}
        </h2>
        <div class="flex">
          <Anchor
            :href="urlButton"
            :to="urlButton"
            class="btn-lg fill-green hover:no-underline ease-in-out duration-150 hover:bg-[#45994526] rounded-btn hover:text-white font-bold"
          >
            {{ $t('buttons.readMore') }}
            <font-awesome-icon
              :icon="['fas', 'arrow-right']"
              class="icon-right-anim"
            />
          </Anchor>
        </div>
      </div>
    </div>
    <nuxt-img
      provider="s3"
      src="/img_background_order_now.svg"
      class="absolute top-0 left-0 right-0 mx-auto"
      loading="lazy"
      alt="Order now image background"
    />
  </div>
</template>

<style scoped lang="scss"></style>
